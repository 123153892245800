@charset "UTF-8";
html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  font-family: Telstra Akkurat;
  font-size: 14px;
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Telstra Akkurat;
}

a {
  color: #1964c8;
  font-family: Telstra Akkurat;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.parent-container {
  max-width: 1360px;
  margin: 0 auto;
}

.inner-container {
  max-width: 1128px;
  margin: 0 auto;
}
@media only screen and (max-width: 1300px) {
  .inner-container {
    max-width: 900px;
  }
}

@media only screen and (max-width: 1023px) {
  .responsive-width {
    max-width: 75%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 375px) {
  .responsive-width {
    max-width: 306px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 640px) {
  .reveal {
    top: 9px;
    width: 95%;
    margin: auto;
    min-height: 0;
    padding-right: 33px;
    height: auto;
  }
}
.field-label, .plan-label, #old-plan-label, #new-plan-label, .progress-label {
  color: #333333;
}

.field-value, .progress-details {
  color: #555555;
  font-weight: normal;
}

.flex-column, .order-summary-right, .order-number-box, .next-step-box, .error-message-box, .error422-message, .error-message {
  display: flex;
  flex-direction: column;
}

.flex-row, .view-dashboard, .how-to-prepare, .tab-box, .search-box .error-msg {
  display: flex;
  flex-direction: row;
}

.small-icon, .milestone-process-icon, .order-processed-state, .order-complete-state img, .order-complete-state, .progress-state-normal img, .progress-state-normal {
  width: 22px;
  height: 22px;
}

.progress-state-big img, .progress-state-big {
  width: 26px;
  height: 26px;
}

.parent-box {
  position: relative;
}

.no-margin {
  margin: 0 !important;
}

.footer-content .active-tab, .dashboard-active-tab {
  background-color: #00aaf3;
}

.footer-content .inactive-tab, .dashboard-inactive-tab {
  background-color: #e1eef8;
}

.account-info {
  display: none;
}

.app-content {
  max-width: 982px;
}

.covid-section-main {
  position: relative;
  justify-content: center;
  overflow: hidden;
  transition: height 0.2s ease-in;
  background: #e5effa;
  height: 62px;
}

.covid-section {
  position: relative;
  justify-content: center;
  overflow: hidden;
  transition: height 0.2s ease-in;
  background: #e5effa;
  display: flex;
}

.covid-parent-first {
  display: flex;
  text-align: center;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.5em;
  font-weight: normal;
}

.covid-parent-second {
  display: flex;
  text-align: center;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.5em;
  font-weight: normal;
}

.covid-text {
  align-self: center;
}

.tell-me-more-link {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  padding: 8px;
  align-self: center;
  line-height: 1.25;
  font-weight: 500;
}

.tell-me-more-link:link,
.tell-me-more-link:visited {
  color: #0064d2;
}

.close-covid-btn {
  position: absolute;
  height: 11px;
  width: 12px;
  right: 0;
  margin-right: 10px;
  top: 11px;
  cursor: pointer;
}

.covid-gradient {
  border-image: linear-gradient(90deg, #0064d2, #5cd6e0 30%, #5e50bf);
  border-image-slice: 1;
  width: 100%;
  border-width: 2px 0 0;
  border-style: solid;
}

.order-summary-header, .overlapped-header {
  font-size: 26px;
  font-weight: normal;
  color: #1a5199;
}

.header-description {
  font-size: 12px;
  line-height: 1.33;
  color: #333333;
  padding: 28px 17px 14.1px 17px;
}

@media (max-width: 466px) {
  .covid-section {
    height: 72px;
  }

  .close-covid-btn {
    margin-right: 11px;
  }

  .covid-section-main {
    height: 82px;
  }
}
.tcom-global-footer {
  width: 100%;
  height: auto;
  background-color: #f4f4f4;
  color: #414141;
}

.tcom-global-footer .footer-container {
  padding: 0 24px 10px 24px;
}

@media (min-width: 768px) {
  .tcom-global-footer .footer-container {
    padding: 60px 56px 112px 56px;
  }
}
@media (min-width: 1024px) {
  .tcom-global-footer .footer-container {
    max-width: 936px;
    margin: auto;
    padding: 80px 0 54px 0;
  }
}
@media (min-width: 1360px) {
  .tcom-global-footer .footer-container {
    max-width: 1128px;
    margin: auto;
    padding-bottom: 49px;
  }
}
.tcom-global-footer .footer-container .footer-links {
  display: block;
}

.tcom-global-footer .footer-container .footer-links .footerList {
  border-bottom: 1px solid #dadada;
}

.tcom-global-footer .footer-container .footer-links .footerList ul li {
  display: flex;
}

@media (min-width: 1024px) {
  .tcom-global-footer .footer-container .footer-links {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .tcom-global-footer .footer-container .footer-links .footerList {
    border-bottom: 0;
  }
}
.tcom-global-footer .footer-container .footer-links .footer-list {
  display: none;
}

@media (min-width: 1024px) {
  .tcom-global-footer .footer-container .footer-links .footer-list {
    display: block;
    width: 225px;
    margin-right: 24px;
  }
}
@media (min-width: 1360px) {
  .tcom-global-footer .footer-container .footer-links .footer-list {
    margin-right: 63px;
  }
}
@media (min-width: 1024px) {
  .tcom-global-footer .footer-container .footer-links div:nth-last-child(1) > div.footer-list {
    margin-right: 0;
  }
}
.tcom-global-footer .footer-container .footer-links .accordion-tab {
  margin-left: -8px;
}

@media (min-width: 1024px) {
  .tcom-global-footer .footer-container .footer-links .accordion-tab {
    display: none;
  }
}
.tcom-global-footer .footer-container .footer-links .accordion-tab .heading-text {
  margin-bottom: 0;
  padding: 20px 0 20px 8px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab label {
  display: block;
  height: 22px;
  cursor: pointer;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab label .accordion-open-icon {
  display: inline-block;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab label .accordion-close-icon {
  display: none;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab .tab-content {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
  display: none;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab .tab-content ul {
  margin-top: 0;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab .tab-content ul li:last-child {
  margin-bottom: 8px;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab input[type=checkbox]:checked + label .accordion-close-icon {
  display: inline-block;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab input[type=checkbox]:checked + label .accordion-open-icon {
  display: none;
}

.tcom-global-footer .footer-container .footer-links .accordion-tab input[type=checkbox]:checked ~ .tab-content {
  max-height: 30em;
  display: block;
}

.tcom-global-footer ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.tcom-global-footer ul li {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .tcom-global-footer ul li {
    margin-bottom: 0;
  }
}
.tcom-global-footer ul li a {
  color: #414141;
  text-decoration: none;
  line-height: 20px;
  font-size: 14px;
  padding: 14px 8px;
}

@media (min-width: 1024px) {
  .tcom-global-footer ul li a {
    padding: 6px 8px;
    margin: 0 -8px;
  }
}
.tcom-global-footer ul li a:hover {
  text-decoration: underline;
}

.tcom-global-footer ul li a:visited {
  color: #414141;
}

.tcom-global-footer ul li .footer-icon {
  height: 16px;
  width: 16px;
  margin-left: 5px;
  vertical-align: bottom;
  margin-bottom: 0.25rem;
}

.tcom-global-footer .heading-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 30px;
}

@media (min-width: 1024px) {
  .tcom-global-footer .heading-text {
    font-size: 20px;
    line-height: 28px;
  }
}
.tcom-global-footer .telstra-social {
  padding-top: 20px;
}

@media (min-width: 1024px) {
  .tcom-global-footer .telstra-social {
    padding: 0;
  }
}
.tcom-global-footer .telstra-social .heading-text {
  margin-bottom: 33px !important;
}

@media (min-width: 1024px) {
  .tcom-global-footer .telstra-social .heading-text {
    margin-bottom: 26px !important;
  }
}
.tcom-global-footer .telstra-social ul {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1024px) {
  .tcom-global-footer .telstra-social ul {
    padding-left: 8px;
  }
}
.tcom-global-footer .telstra-social ul li {
  display: inline-block;
  margin-bottom: 24px;
  flex: 0 50%;
}

@media (min-width: 768px) {
  .tcom-global-footer .telstra-social ul li {
    flex: none;
    margin-bottom: 12px;
    margin-right: 32px;
  }

  .tcom-global-footer .telstra-social ul li:last-child {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .tcom-global-footer .telstra-social ul li {
    margin-right: 56px;
  }
}
.tcom-global-footer .telstra-social ul li .footer-icon {
  height: 24px;
  width: 32px;
  opacity: 0.8;
  margin-left: 0;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .tcom-global-footer .telstra-social ul li .footer-icon {
    width: 34px;
  }
}
@media (min-width: 1024px) {
  .tcom-global-footer .telstra-social ul li .footer-icon {
    width: 32px;
  }
}
.tcom-global-footer .telstra-social ul li a {
  padding: 0;
  display: flex;
  align-items: center;
}

.desktop-modal-menu {
  z-index: 1050;
  left: 0;
  right: 0;
  padding: 0;
  visibility: hidden;
  padding-top: 0;
  min-height: 100vh;
  outline: 0;
  background-color: #fff;
  position: absolute;
  top: 80px;
}

@-webkit-keyframes backgroundShift {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 50%;
  }
}
@keyframes backgroundShift {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 50%;
  }
}
@-webkit-keyframes arrowLineStretch {
  0% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
  35% {
    -webkit-transform: scaleX(1.2) translateX(-3px);
    transform: scaleX(1.2) translateX(-3px);
  }
  100% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
}
@keyframes arrowLineStretch {
  0% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
  35% {
    -webkit-transform: scaleX(1.2) translateX(-3px);
    transform: scaleX(1.2) translateX(-3px);
  }
  100% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
}
@keyframes backgroundShift {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 50%;
  }
}
@keyframes arrowLineStretch {
  0% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
  35% {
    -webkit-transform: scaleX(1.2) translateX(-3px);
    transform: scaleX(1.2) translateX(-3px);
  }
  100% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
}
@keyframes backgroundShift {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 50%;
  }
}
@keyframes arrowLineStretch {
  0% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
  35% {
    -webkit-transform: scaleX(1.2) translateX(-3px);
    transform: scaleX(1.2) translateX(-3px);
  }
  100% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
}
@keyframes backgroundShift {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 50%;
  }
}
@keyframes arrowLineStretch {
  0% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
  35% {
    -webkit-transform: scaleX(1.2) translateX(-3px);
    transform: scaleX(1.2) translateX(-3px);
  }
  100% {
    -webkit-transform: scaleX(1) translateX(0);
    transform: scaleX(1) translateX(0);
  }
}
.globalFooter {
  position: relative;
  bottom: 0;
  width: 100vw;
  margin-top: 2.5rem;
}

@media screen and (-ms-high-contrast: active) {
  .globalFooter .able-icon use {
    fill: #fff;
  }
}
@media (prefers-contrast: forced) {
  .globalFooter .able-icon use {
    fill: #fff;
  }
}
@media (forced-colors: active) {
  .globalFooter .able-icon use {
    fill: #fff;
  }
}
.heading-text {
  position: relative;
}

.mt-global-footer-expander {
  position: absolute;
  top: 0;
  right: 0;
}

.mt-global-footer-expander:after, .mt-global-footer-expander:before {
  content: "";
  position: absolute;
  right: 0.5rem;
  top: 2.5rem;
  display: block;
  width: 0.8rem;
  height: 0;
  border: 1px solid var(--colour-primary);
  border: 1px solid var(--colour-primary);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.mt-global-footer-expander:after, .mt-global-footer-expander:before {
  top: 1.9rem;
  background-color: #414141;
}

input[type=checkbox]:not(:checked) ~ label .mt-global-footer-expander:before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

input[type=checkbox]:not(:checked) ~ label .mt-global-footer-expander:after {
  -webkit-transform: rotate(-270deg);
  transform: rotate(-270deg);
}

.footerList:last-child a .nav-icon {
  display: inline-block;
  margin-left: 0.25rem;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.showhide-footer .mt-showhide__button {
  background-color: transparent;
  background-color: initial;
  padding-left: 0.5rem;
  padding-right: 0.8rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

.mt-showhide__button[aria-expanded=false] .mt-showhide__button__icon:before {
  transform: rotate(-180deg);
}

.showhide-footer .mt-showhide__content__inner a {
  padding-bottom: 14px;
}

.showhide-footer .mt-showhide__button__icon::after, .showhide-footer .mt-showhide__button__icon::before {
  top: 2rem;
  width: 0.8rem;
}

.mt-showhide.showhide-footer {
  border-top: none;
}

.mt-showhide.showhide-footer:last-child {
  border-bottom: none;
}

.tcom-global-footer ul li a:focus {
  box-shadow: 0 0 0 3px #0064d2;
}

.tcom-global-footer ul li {
  padding-left: 3px;
}

.tcom-global-footer ul li:first-of-type {
  padding-top: 3px;
}

@media (min-width: 768px) {
  .tcom-global-footer ul li {
    padding-left: unset;
  }

  .tcom-global-footer ul li:first-of-type {
    padding-top: unset;
  }
}
.globalFooter {
  width: 100%;
}
.globalFooter .mt-showhide__button__icon::before,
.globalFooter .mt-showhide__button__icon::after {
  background-color: #414141 !important;
}

.footer-container {
  padding: 4rem 0rem;
  background-color: #f4f4f4;
}
.footer-container div h2 {
  color: #414141;
  font-size: 1.2rem;
  font-weight: bold;
}
.footer-container div h2 i {
  font-size: 1.4rem;
}
.footer-container div p {
  color: #757575;
}
@media only screen and (max-width: 767px) {
  .footer-container {
    padding: 0.5rem 0 0;
  }
}

.desktop-footer {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .desktop-footer {
    display: grid;
    grid: 80% auto/repeat(4, 1fr);
    margin: auto;
    max-width: 1128px;
  }
}

.footer-link-lists h2 {
  font-size: 20px;
  color: #414141;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 25px;
  margin-bottom: 30px;
}
.footer-link-lists ul {
  list-style: none;
  margin: 0;
}
.footer-link-lists ul a {
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  color: #414141;
  margin: 0.5rem 0rem;
  display: inline-block;
}
.footer-link-lists ul a .external-link-img {
  vertical-align: sub;
  margin-left: 0.3rem;
}
.footer-link-lists ul a:hover {
  text-decoration: underline;
}

.footer-social-links {
  grid-area: 2/1/3/4;
  margin-top: 1.5rem;
}
.footer-social-links ul {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.footer-social-links ul a {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #333333;
  font-size: 14px;
  letter-spacing: -0.09px;
}
.footer-social-links ul a .platform-img {
  margin-right: 0.4rem;
}

.footer-dock {
  display: flex;
  justify-content: flex-end;
  transition: opacity 0.35s ease;
  z-index: 1010;
  position: relative;
  margin-bottom: -55px;
}
.footer-dock .footer-dock-button-container .footer-dock-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 80px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  text-decoration: none;
  color: #414141;
  background-color: #fff;
}
.footer-dock .footer-dock-button-container .footer-dock-button img {
  transform: rotate(-90deg);
  vertical-align: middle;
  margin-left: 0.5rem;
}
@media only screen and (max-width: 1023px) {
  .footer-dock .footer-dock-button-container {
    margin-right: -4rem;
  }
}
@media only screen and (max-width: 767px) {
  .footer-dock .footer-dock-button-container {
    margin-right: -3rem;
  }
}
@media only screen and (max-width: 375px) {
  .footer-dock .footer-dock-button-container {
    margin-right: -0.5rem;
  }
}

.footer-dock.docked {
  position: sticky;
  bottom: 1.5rem;
}

.mobile-footer {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .mobile-footer {
    display: block;
    max-width: 90%;
    margin: auto;
  }
  .mobile-footer ul {
    list-style: none;
    margin: unset;
  }
}

.dropdown {
  padding: 17px 0;
  position: relative;
  border-bottom: 1px solid #d2d2d2;
}
.dropdown a {
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
}
.dropdown [data-toggle=dropdown] {
  display: block;
  color: #414141;
  background: #f4f4f4;
}
.dropdown [data-toggle=dropdown]:before {
  content: url("../images/plusIconTD.svg");
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  top: 17px;
  right: 10px;
}
.dropdown > .dropdown-menu {
  height: 0;
  padding: 0;
  margin: unset;
  overflow: hidden;
  list-style: none;
  transform-origin: 50% 0%;
  transition: all 0.5s ease;
  background: #f4f4f4;
}
.dropdown > .dropdown-menu li {
  padding: 0;
}
.dropdown > .dropdown-menu li a {
  max-width: max-content;
  position: relative;
  z-index: 999;
  display: block;
  color: #414141;
  padding: 10px 0;
  font-size: 14px;
  font-weight: normal;
}
.dropdown > .dropdown-menu li a img {
  margin-left: 0.5rem;
  vertical-align: text-bottom;
}
.dropdown > .dropdown-menu li a:hover {
  text-decoration: underline;
}
.dropdown > input[type=checkbox] {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  cursor: pointer;
}
.dropdown > input[type=checkbox]:checked ~ .dropdown-menu {
  height: auto;
  padding: 24px 0;
}
.dropdown > input[type=checkbox]:checked + a[data-toggle=dropdown]:before {
  content: url("../images/minusIconTD.svg");
}

.mobile-footer .mobile-social-links {
  margin-top: 1.5rem;
}
.mobile-footer .mobile-social-links h2 {
  font-size: 16px;
  font-weight: 700;
  color: #414141;
  margin-bottom: 2rem;
}
.mobile-footer .mobile-social-links div {
  display: flex;
  width: 70%;
  flex-flow: row wrap;
  justify-content: space-between;
}
.mobile-footer .mobile-social-links div a {
  white-space: nowrap;
  color: #414141;
  line-height: 20px;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 1rem;
}
.mobile-footer .mobile-social-links div a:hover {
  text-decoration: underline;
}
.mobile-footer .mobile-social-links div a img {
  margin-right: 0.5rem;
}
.mobile-footer .mobile-social-links div a:not(:last-of-type) {
  margin-right: 1rem;
}
@media only screen and (max-width: 767px) {
  .mobile-footer .mobile-social-links div a {
    flex: 0 1 calc(50% - 2rem);
    margin: 1rem 0;
  }
}

.footer-links .accordion-tab .heading-text span:nth-child(1) {
  font-size: 16px;
  color: #414141;
}
@media only screen and (min-width: 1024px) {
  .footer-links .footer-list h2 span {
    font-size: 20px;
  }
}

a[href="https://www.telstrawholesale.com.au/"] svg,
a[href="https://www.telstraglobal.com/"] svg,
a[href="http://media.telstra.com.au/home"] svg,
a[href="https://www.telstrahealth.com/"] svg {
  display: none !important;
}

a[href="https://crowdsupport.telstra.com.au/t5/Find-your-nearest-Telstra-Retail/ct-p/tstore"]:after,
a[href="https://outages.telstra.com/"]:after,
a[href="https://careers.telstra.com/home"]:after,
a[href="https://www.telstra.com.au/my-account/troubleshooting-tool"]:after,
a[href="https://www.telstrawholesale.com.au/"]:after,
a[href="https://www.telstraglobal.com/"]:after,
a[href="http://media.telstra.com.au/home"]:after,
a[href="https://www.telstrahealth.com/"]:after {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45 45' width='15px' height='15px' fill='rgb(65, 65, 65)'><path d='M30.1 38H10V17.9h10c.6 0 1.1-.2 1.4-.6.4-.4.6-.9.6-1.4 0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2V40c0 1.1.9 2 2 2h24.1c1.1 0 2-.9 2-2V28c0-1.1-.9-2-2-2-.6 0-1.1.2-1.4.6-.4.4-.6.9-.6 1.4v10z' /><path d='M42 7.6c0-.1 0-.1-.1-.2 0-.1 0-.1-.1-.2 0-.1-.1-.1-.1-.2s-.1-.1-.1-.2c-.1-.2-.3-.4-.6-.6l-.1-.1c-.1 0-.1-.1-.2-.1s-.1 0-.2-.1c-.1 0-.1 0-.2-.1H23c-1.1 0-2 .9-2 2s.9 2 2 2h12.1L24.6 20.5l-6 6c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7s1-.2 1.4-.6l6-6L38 12.8V25c0 1.1.9 2 2 2s2-.9 2-2V7.6z' /></svg>");
  margin-left: 0.4rem;
}

#menu-help,
#menu-about,
#menu-privacy,
#menu-other {
  padding-left: 8px;
}

@media only screen and (max-width: 1023px) {
  .socialIcons.parbase {
    padding-left: 8px;
  }
}

.footer-icon svg path {
  fill: #333333;
  opacity: 0.8;
}

.collapsible-footer {
  text-align: left;
  outline: none;
}

.collapsible-footer:after {
  content: "+";
  float: right;
  margin-left: 5px;
  margin-top: 20px;
  font-size: large;
  transform: rotate(-180deg);
  transition: 0.3s ease-out;
}

.active:after {
  content: "−";
  transform: rotate(0deg);
  transition: 0.3s ease-out;
}

.collapsible-footer-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.color-gradient.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: 1010;
}
.color-gradient.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  background: linear-gradient(90deg, #0064d2 0%, #5cd6e0 31.59%, #5e50bf 100%);
}
@media only screen and (max-width: 1023px) {
  .color-gradient.sticky {
    height: 50px;
  }
}

.color-gradient.sticky.fixed {
  height: 80px !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.25s ease;
}
@media only screen and (max-width: 1023px) {
  .color-gradient.sticky.fixed {
    height: 50px !important;
  }
}

.desktop-navbar {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .desktop-navbar {
    display: grid;
    grid-template-columns: 30% auto 25%;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
}
@media only screen and (max-width: 1128px) {
  .desktop-navbar {
    grid-template-columns: 35% auto 30%;
  }
}

.mobile-navbar {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .mobile-navbar {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  .mobile-navbar .mobile-telstra-logo {
    width: 32px;
    height: 32px;
  }
  .mobile-navbar .mobile-telstra-logo img {
    width: 100%;
    height: 100%;
  }
  .mobile-navbar .right-functions a {
    padding: 0 1rem;
    color: #414141;
    font-size: 14px;
    text-decoration: none;
  }
  .mobile-navbar .right-functions .mobile-menu-icon {
    width: 24px;
    height: 24px;
  }
}

.mobile-overlay {
  list-style: none;
  margin: unset;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  border-top: 2px solid #0064D2;
}
.mobile-overlay li {
  margin: 0 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #E8E8E8;
}
.mobile-overlay li a {
  font-size: 18px;
  line-height: 19px;
  font-weight: 400;
  padding: 1rem 1.5rem 1rem 0;
  color: #414141;
  display: inline-block;
}
.mobile-overlay li:first-of-type {
  margin-top: 3rem;
}
.mobile-overlay li:last-of-type {
  border-bottom: 1px solid #414141;
}

.segmented-nav-list {
  display: flex;
  list-style: none;
  margin: unset;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.segmented-nav-list .icons-logo-blue {
  width: 40px;
  height: 40px;
}
.segmented-nav-list .icons-logo-blue a,
.segmented-nav-list .icons-logo-blue img {
  height: 100%;
  width: 100%;
}
.segmented-nav-list .icons-logo-blue .telstra-logo {
  display: inline-block;
  margin: unset;
}
.segmented-nav-list li a:not(.telstra-logo) {
  padding: 0 1rem;
  color: #414141;
  font-size: 16px;
  text-decoration: none;
}
.segmented-nav-list li a:not(.telstra-logo) sup {
  display: inline-block;
  border-bottom: 1px solid transparent;
  padding-bottom: 10px;
}
.segmented-nav-list li a:not(.telstra-logo):hover {
  text-decoration: underline;
}
.segmented-nav-list li a:not(.telstra-logo):hover sup {
  border-bottom: 1px solid #414141;
}

.nav-1 {
  height: 100%;
  grid-area: 1/1/2/2;
}

.nav-2 {
  height: 100%;
  grid-area: 1/3/2/-1;
}
.nav-2 ul li:nth-child(2) {
  border-right: 1px solid #d8d8d8;
}

.module-navigation-breadcrumb nav ul {
  list-style: none;
}
@media only screen and (min-width: 1024px) {
  .module-navigation-breadcrumb {
    max-width: unset;
    margin: unset;
    height: 48px;
    width: 264px;
  }
}

.breadcrumb-wrap {
  display: inline-block;
  position: relative;
  padding: 10px 5px 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.breadcrumb-wrap .breadcrumb, .breadcrumb-wrap .breadcrumb a {
  color: #767676;
}
.breadcrumb-wrap .breadcrumb a {
  text-decoration: underline;
}
.breadcrumb-wrap:first-child .separator {
  display: none;
}
.breadcrumb-wrap:not(:last-child) a:hover {
  color: #0064d2;
}
.breadcrumb-wrap:last-child a {
  text-decoration: none;
  cursor: text;
}
.breadcrumb-wrap .separator.back {
  display: none;
}
@media only screen and (max-width: 375px) {
  .breadcrumb-wrap .separator {
    display: none;
  }
  .breadcrumb-wrap .separator.back {
    display: inline-block;
  }
  .breadcrumb-wrap:not(:nth-last-of-type(2)) {
    display: none;
  }
}

.static-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}
.static-content-text {
  align-self: center;
  white-space: nowrap;
  margin-left: 4.5rem;
}
.static-content-text span {
  display: inline-block;
  position: relative;
  padding: 24px 16px 24px 35px;
  border-image: linear-gradient(180deg, #a0148c, #ffad6e 30%, #ff7aa3);
  border-left: 4px solid transparent;
  border-image-slice: 0 0 0 1;
}
.static-content-text span h1 {
  font-size: 32px;
  font-weight: 700;
  color: #282828;
  line-height: 45px;
}
.static-content-text span p {
  color: #414141;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}
.static-content-image-desktop {
  max-height: 100%;
}
.static-content-image-mobile {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .static-content {
    max-width: 75%;
    margin: 0 auto;
    flex-flow: column-reverse;
    margin-bottom: 40px;
  }
  .static-content-image-desktop {
    width: 100%;
  }
  .static-content-text {
    align-self: start;
    white-space: unset;
    margin-left: 0;
  }
  .static-content-text .mobile-none {
    display: none;
  }
  .static-content-text span {
    padding: 10px 16px 10px 35px;
  }
  .static-content-text h1 {
    font-size: 20px !important;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
  }
  .static-content-text p {
    font-size: 16px !important;
    font-weight: normal;
  }
}
@media only screen and (max-width: 375px) {
  .static-content {
    max-width: 100%;
  }
  .static-content-text {
    margin-top: 1.5rem;
  }
  .static-content-image-desktop {
    display: none;
  }
  .static-content-image-mobile {
    display: inline-block;
    transform: scale(1.2);
  }
}

.able-mt-button--low-emphasis svg {
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.retrieve-error {
  display: flex;
  border-bottom: 2.5px solid #7e0221;
  padding: 1rem 1rem 0;
  box-shadow: 0 0 5px 5px #eee;
  background-color: #fff;
  align-items: flex-start;
}
.retrieve-error img {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}
.retrieve-error span {
  flex: 0 1 90%;
}
.retrieve-error span h2 {
  color: #282828;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
.retrieve-error span p {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 22px;
  font-weight: 400;
}

.tracker-form {
  color: #414141;
  border-bottom: 1px solid #e8e8e8;
}
.tracker-form p {
  font-size: 20px;
  font-weight: normal;
}
.tracker-form .suggestions-block {
  color: #414141;
}
.tracker-form .suggestions-block h3 {
  font-size: 16px;
  font-weight: 400;
}
.tracker-form .suggestions-block p {
  font-weight: normal;
  margin-bottom: unset;
}
.tracker-form form button {
  width: 358px;
  height: 56px;
}
.tracker-form form button span {
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 1023px) {
  .tracker-form p {
    font-size: 16px;
  }
  .tracker-form .suggestions-block h3 {
    color: #282828;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
  }
  .tracker-form form button {
    width: 306px;
  }
  .tracker-form form button span {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .tracker-form {
    padding-top: 35px;
  }
}

.alternative-methods {
  color: #282828;
}
.alternative-methods h3 {
  font-size: 20px;
  font-weight: 700;
}
.alternative-methods .alt-method {
  font-weight: normal;
  font-size: 20px;
}
.alternative-methods .alt-method button span {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.alternative-methods .alt-method p {
  color: #414141;
  line-height: 28px;
  font-weight: normal;
}
.alternative-methods .alt-method .able-mt-button--low-emphasis {
  text-decoration: none;
}
.alternative-methods .alt-method .able-mt-button--low-emphasis span {
  font-weight: 400;
  font-size: 20px;
}
.alternative-methods .alt-method a:not(.able-mt-button--low-emphasis) {
  font-weight: 400;
  font-size: 20px;
  color: #0062da;
  text-decoration: none;
  transition: color 0.2s ease;
}
.alternative-methods .alt-method a:not(.able-mt-button--low-emphasis):hover {
  color: #001e82;
}
.alternative-methods .alt-method a:not(.able-mt-button--low-emphasis):hover > span {
  margin-left: 0.5rem;
  background-color: #001e82;
}
.alternative-methods .alt-method .right-arrow {
  vertical-align: middle;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #0062da;
  mask: url("../images/RightArrowTD.svg") no-repeat center;
  margin-left: 0.2rem;
  transition: margin-left 0.2s ease;
}
@media only screen and (max-width: 1023px) {
  .alternative-methods h3 {
    font-size: 16px;
    line-height: 22px;
  }
  .alternative-methods .alt-method p {
    font-size: 16px;
    line-height: 24px;
  }
  .alternative-methods .alt-method a {
    font-size: 16px;
    line-height: 24px;
  }
  .alternative-methods .alt-method button span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .alternative-methods .alt-method .able-mt-button--low-emphasis span {
    font-size: 16px;
  }
}

.strip-banner {
  padding: 3.56rem 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.strip-banner .strip-banner-function {
  flex: 0 1 30%;
  display: flex;
  align-items: flex-start;
}
.strip-banner .strip-banner-function img {
  width: 24px;
  height: 24px;
}
.strip-banner .strip-banner-function span {
  margin-left: 0.7rem;
}
.strip-banner .strip-banner-function span h3 {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.1px;
}
.strip-banner .strip-banner-function span p {
  color: #757575;
  font-size: 14px;
  letter-spacing: -0.2px;
}
.strip-banner .strip-banner-function span a {
  text-decoration: none;
  border-bottom: 1px solid #0064d2;
  padding-bottom: 1px;
}
.strip-banner .strip-banner-function span a:hover {
  color: #001e82;
}
@media only screen and (max-width: 1023px) {
  .strip-banner .strip-banner-function {
    min-width: 300px;
  }
  .strip-banner .strip-banner-function:not(:last-of-type) {
    margin-bottom: 5rem;
  }
}
@media only screen and (max-width: 375px) {
  .strip-banner .strip-banner-function {
    min-width: unset;
    flex: unset;
  }
}

.usabilla-feedback {
  text-align: center;
  margin: 0 0 -24px;
  padding-top: 32px;
}
.usabilla-feedback .app-content {
  margin: 0 auto;
}
.usabilla-feedback h2 {
  font-size: 1rem;
  color: #414141;
}
.usabilla-feedback div i {
  font-size: 2rem;
}
.usabilla-feedback div i:not(:last-child) {
  margin-right: 1rem;
}

.alt-method-link {
  text-decoration: none;
}
.alt-method-link i {
  margin-left: 0.2rem;
  transition: all 0.2s ease;
}
.alt-method-link:hover i {
  margin-left: 0.8rem;
}

.able-mt-button--low-emphasis svg {
  vertical-align: middle;
}

.group {
  position: relative;
  margin: 33px 0 35px;
}
.group .group-input {
  background-color: #fff;
  font-size: 20px;
  color: #282828;
  padding: unset;
  width: 358px;
  border: none;
  outline: none;
  border-bottom: 1.88px solid #414141;
  margin: unset;
  box-shadow: unset;
  font-weight: normal;
}
.group .group-input:focus {
  outline: none;
  box-shadow: unset;
  border: none;
  border-bottom: 1.88px solid #0064d2;
}
.group .group-input:valid ~ label {
  top: -60px;
}
.group .group-input:valid + img {
  display: inline-block;
}
.group label {
  position: absolute;
  left: 0;
  top: -25px;
  color: #414141;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  pointer-events: none;
  transform-origin: left;
  transition: top 70ms ease-in-out;
}
.group .clear-input {
  display: none;
  position: absolute;
  top: 10px;
  left: 335px;
  cursor: pointer;
}
@media only screen and (max-width: 1023px) {
  .group .group-input {
    max-width: 100%;
    width: 306px;
  }
  .group .clear-input {
    left: 285px;
  }
}

.invalid-order-message {
  display: table;
  color: #C91800;
  margin-top: -15px;
}
@media only screen and (min-width: 1024px) {
  .invalid-order-message {
    max-width: 65%;
  }
}

.err-message-prompt-icon-container {
  display: table-cell;
  height: 100%;
  width: 25px;
  padding-right: 10px;
}

.invalid-order-message .input-error-msg {
  display: table-cell;
  font-weight: 400;
  font-size: 14px;
}

#retrieveErrorMsg {
  color: #c91800;
}

.order-progress-box .react-add-to-calendar__dropdown ul li a i:before {
  font-family: "Fontawesome";
  font-size: 1.05rem;
}

.m-btm-2 {
  margin-bottom: 16px;
}

.m-btm-3 {
  margin-bottom: 24px;
}

.p-btm-7 {
  padding-bottom: 56px;
}

.m-btm-5 {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 700;
}

.m-btm-10 {
  margin-bottom: 80px;
}

.m-top-1 {
  margin-top: 8px;
}

.m-top-2 {
  margin-top: 16px;
}

.m-top-5 {
  margin-top: 40px;
}

.p-top-7 {
  padding-top: 56px;
}

.p-btm-10 {
  padding-bottom: 80px;
}

.telstra-spinner-box {
  height: 50vh;
  display: flex;
}

.telstra-spinner-box div {
  margin: auto;
  height: 48px;
  width: 48px;
}

.appointment-details-spinner-box {
  position: unset;
  top: 40%;
  left: 40%;
}

.appointment-details-spinner-box-mobile {
  height: 50vh;
  display: flex;
}

.spinner-box-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
  height: 24px;
}

.appointment-details-spinner-box-desktop {
  height: 350px;
  width: 650px;
  display: flex;
}

.appointment-details-spinner-box-desktop div {
  margin: auto;
  height: 55px;
  width: 55px;
}

.error-message {
  padding: 34px 16px 92px 16px;
  align-items: center;
}
.error-message img {
  height: 50px;
  width: 50px;
}
.error-message .error-message-content {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #333333;
  line-height: 19px;
  padding: 11px 32px 0 27px;
}

.landing-page-error-message-box {
  margin: 15px 100px 0 100px;
  padding: 0 16px;
}

.error-message-label {
  float: left;
  padding: 20px 0 8px;
}

.error-text {
  padding: 20px 0 8px;
  max-width: 100%;
  margin-left: 40px;
}
.error-text span {
  font-size: 18px;
}

.error-subtext {
  margin-left: 51px;
}
.error-subtext span {
  font-size: 16px;
}

@media screen and (max-width: 601px) {
  .landing-page-error-message-box {
    margin: 15px 15px 0 15px;
    padding: 0 16px;
  }
}
.error422-message {
  padding: 34px 16px 92px 16px;
  align-items: left;
}

.error422-message .error422-message-content {
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  color: #333333;
  line-height: 25px;
  padding: 11px 32px 0 27px;
}

.error422-message .error422-message-content .list_indent {
  margin-left: 30px;
}

.error-message-box {
  margin: 15px 15px 0 15px;
  padding: 0 16px;
  align-items: left;
}

.error-message-box img {
  height: 50px;
  width: 50px;
  padding-right: 15px;
}

.error-message-box .error-message-content {
  font-size: 20px;
  font-weight: normal;
  color: #333333;
  line-height: 40px;
}

.error-message-box .empty-header {
  padding: 21px;
}

.error-message-box .empty-header img {
  float: left;
  padding-right: 20px;
}

.error-message-box .empty-header .error-message-content {
  padding-top: 7px;
}

.search-box {
  text-align: center;
  margin: 1px 0 71px 0;
  text-align: left;
  margin-bottom: 100px;
}
.search-box input {
  max-width: 280px;
  height: 40px;
  font-size: 20px;
  width: 80%;
  margin-left: 17px;
}
.search-box button {
  position: absolute;
  background-color: #00aaf3;
}
.search-box .error-msg {
  align-items: flex-start;
  text-align: left;
  padding: 0 17px 15px 17px;
  font-size: 12px;
}
.search-box .error-msg img {
  width: 16px;
  height: 16px;
}
.search-box .error-msg span {
  margin-left: 5px;
  line-height: 14px;
  font-weight: normal;
}
.search-box .find-order-id-help {
  border-width: 0px;
  position: absolute;
  margin-left: 17px;
  cursor: pointer;
  left: 3px;
  margin-top: 7px;
  width: 239px;
  height: 17px;
  font-family: "Helvetica";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1585CF;
}
.search-box .find-order-by-signing {
  font-family: "Helvetica" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  margin-top: 50px;
  margin-left: 17px;
}
.search-box #sign-in-button {
  border: none;
  color: white;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 5px;
}

.appointment-manager-close {
  font-family: "Helvetica";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: #1585CF;
}

hr {
  margin: 0em;
  border-width: 2px;
}

.popup {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 10000px;
  height: 10000px;
  background-color: #949494;
  opacity: 0.898039;
  z-index: 1011;
}

.popup-temp {
  position: absolute;
  background: white;
  z-index: 1012;
  height: fit-content;
  margin-top: -250px;
}
.popup-temp li {
  font-weight: 400;
}
.popup-temp li span {
  font-weight: 700;
}

.appointment-manager-content {
  padding: 0 0 26px 26px;
}

.popup-inner {
  position: absolute;
  background: white;
  z-index: 1012;
  padding: 64px;
  height: 800px;
  margin-top: -250px;
}
.popup-inner li {
  font-weight: 400;
}
.popup-inner li span {
  font-weight: 700;
}

.desktop-layout {
  margin-left: 138px;
}

.ipad-layout {
  margin-left: 31px;
}

.mobile-layout {
  margin-left: 0px;
}

.cl1 {
  cursor: pointer;
  left: 886px;
  top: 124px;
}

.cl2 {
  outline: none;
  border-width: 0px;
  position: absolute;
  right: 20px;
  top: 18px;
  width: 26px;
  height: 26px;
  background: inherit;
  background-color: #379ad2;
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Helvetica";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #FFFFFF;
}

.cl3 {
  top: 5px;
  transform-origin: 11px 8px 0px;
  border-width: 0px;
  position: absolute;
  right: 15px;
  top: 20px;
  width: 22px;
  word-wrap: break-word;
  color: white;
}

.head-text {
  font-size: 19px;
  font-weight: bold;
}

.image {
  border-width: 0px;
  position: absolute;
  width: 338px;
  height: auto;
}

.img-src {
  border-width: 0px;
  position: absolute;
  margin-left: 102px;
  top: 0px;
  width: 338px;
  height: 486px;
}

.desktop-view {
  display: block;
}

@media screen and (min-width: 601px) {
  .desktop-view {
    display: block;
  }

  .popup-inner {
    height: 850px;
    width: 90%;
    left: 5%;
    right: 5%;
  }
}
@media screen and (max-width: 600px) {
  .desktop-view {
    display: none;
  }

  .popup-inner {
    height: 600px;
    width: 90%;
    left: 5%;
    right: 5%;
  }
}
@media screen and (max-width: 330px) {
  .desktop-view {
    display: none;
  }

  .popup-inner {
    height: 800px;
    width: 90%;
    left: 5%;
    right: 5%;
  }
}
.dashboard-inactive-tab, .dashboard-active-tab {
  display: flex;
  justify-content: center;
  padding-top: 14px;
  width: 49.5%;
}

.dashboard-active-tab {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
}

.dashboard-inactive-tab {
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
  color: #333333;
}

.tab-box {
  height: 48px;
  position: relative;
  justify-content: space-between;
}

.active-tab-icon {
  position: absolute;
  top: 48px;
}

.overlapped-header {
  padding-left: 17px;
  padding-top: 20px;
}

.order-separator {
  height: 16px;
  background-color: #e1eef8;
}

.orders-box {
  border-bottom: 1px solid #949494;
}

.empty-order {
  padding: 18px 0 0 17px;
  height: 220px;
  font-size: 14px;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty-order img {
  margin-bottom: 24px;
}

.have-question {
  font-size: 26px;
  font-weight: normal;
  color: #333333;
  text-align: center;
  padding: 18px 0 19px 0;
}

.footer-content .inactive-tab, .footer-content .active-tab {
  display: flex;
  justify-content: center;
  padding-top: 14px;
  width: 33%;
}

.footer-content .tab-box {
  height: 60px;
}
.footer-content .tab-box .tab-img {
  height: 39px;
  width: 42px;
  padding-bottom: 10px;
}
.footer-content .active-tab-icon {
  top: 59px;
}
.footer-content .active-tab {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
}
.footer-content .inactive-tab {
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
  color: #333333;
}
.footer-content .content-label {
  font-size: 18px;
  color: #333333;
  padding-bottom: 16px;
  line-height: 1;
  font-weight: normal;
}
.footer-content .content-box {
  padding: 15px 16px;
}
.footer-content .description {
  font-size: 13px;
  color: #333333;
  padding-bottom: 12px;
  line-height: 16px;
}
.footer-content a span {
  font-size: 13px;
}
.footer-content a img {
  padding-right: 5px;
}

@media screen and (min-width: 601px) {
  .desktop-view-only {
    display: block;
  }

  .mobile-view-only {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .desktop-view-only {
    display: none;
  }

  .mobile-view-only {
    display: block;
  }
}
.hide {
  display: none;
}

.show {
  display: block;
}

.order-progress-box {
  margin: 28px 28px 0 28px;
}

.progress-label {
  padding-bottom: 3px;
}

.plan-label, #old-plan-label, #new-plan-label {
  padding-bottom: 15px;
}

.progress-details {
  line-height: 1.29;
}

.plan-label, #old-plan-label, #new-plan-label {
  padding-bottom: 15px;
}

.progress-bar {
  border-right: 4px solid #d8d8d8;
  margin-right: 16px;
  position: relative;
}

.progress-state-big, .progress-state-normal {
  position: absolute;
  top: 21px;
  background-color: #ffffff;
}

.progress-state-normal {
  right: -13.1px;
}

.progress-state-normal img {
  vertical-align: baseline;
}

.progress-state-big {
  right: -15px;
}

.progress-step-box {
  padding-left: 8px;
}

.progress-step-middle {
  padding: 20px 0;
  border-bottom: 1px solid #9b9b9b;
}

.internet-connection-info-bubble {
  padding-bottom: 15px;
}

.attendance-label {
  color: #555555;
  padding: 5px 0 6px 0;
}

.progress-link {
  padding-top: 10px;
}

.progress-link img {
  margin-right: 7px;
}

.progress-link-nbnappointment img {
  padding-top: 15px;
  margin-right: 7px;
}

.appointment-info {
  padding-bottom: 9px;
}

.appointment-box {
  padding: 10px 0 17px 0;
}

.appt-date {
  padding-bottom: 10px;
}

.progress-step-inprogress {
  background-color: rgba(225, 238, 248, 0.5);
  padding: 15px;
}

.configurable-contact-message {
  padding-top: 5px;
  font-weight: 500;
}

.react-add-to-calendar {
  padding-top: 2px;
  position: relative;
}

.react-add-to-calendar__button {
  display: inline-block;
  padding-left: 30px;
}

.react-add-to-calendar__wrapper {
  position: relative;
}

.react-add-to-calendar__button:before {
  content: "";
  display: inline-block;
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  font-size: 21px;
  padding: 2px 7px 0 0;
  color: #343433;
  position: absolute;
  top: -8px;
  left: 1px;
}

.react-add-to-calendar__dropdown {
  position: absolute;
  top: 25px;
  left: 1px;
  width: 143px;
  padding: 5px 0 5px 8px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid #9b9b9b;
  background-color: #fff;
}

.react-add-to-calendar__dropdown ul {
  list-style-type: none;
  margin: 0;
}

.react-add-to-calendar__dropdown ul a {
  color: #555555;
}

.react-add-to-calendar__dropdown ul a i {
  padding-right: 8px;
}

.how-to-prepare {
  line-height: 15.5px;
  align-items: baseline;
}

.reschedule-message-content {
  font-weight: normal;
  text-align: left;
  color: #555555;
  line-height: 17px;
}

.hardware-delivery-text-bubble, .modem-text-bubble {
  margin-top: 5px;
}

.nbn-appt-img {
  height: 90px;
}

.nbn-appt-details {
  position: relative;
}
.nbn-appt-details .progress-details {
  position: absolute;
  font-size: 13px;
  top: 56px;
  width: 313px;
  left: 182px;
  transform: translate(-50%, -50%);
}

.change-form {
  margin-left: 9px;
  margin-top: 15px;
}

.change-order-title {
  font-size: 17px;
  color: #1A5199;
}

.delivery-status {
  color: #00ac1a;
}

.logistic-panel {
  display: flex;
  margin-top: 12px;
}

.logistic-img-box {
  margin-right: 10px;
}

.logistic-img {
  width: 29px;
  height: 29px;
}

.shipment-message-icon {
  margin-top: 0.35rem !important;
}

.logistic-link {
  line-height: 1.1;
}

.logistic-label {
  line-height: 1;
  color: #4a4a4a;
  padding-bottom: 3px;
}

.hardware-items {
  padding-top: 6px;
}

.download-telstra-app {
  padding-top: 20px;
}

.store-pickup-label {
  padding-bottom: 20px;
}

.telstra-logo {
  height: 32px;
  width: 28px;
  margin: 7px 12px 0 16px;
}

.telstra-logo-appointment {
  height: 32px;
  width: 28px;
  margin: 7px 17px 0 26px;
}

.header-label {
  font-size: 17px;
  text-align: left;
  color: #333333;
  padding-top: 10px;
}

.header-box {
  padding: 14px 0 18px 0;
  margin: 0 !important;
}

.auth-icon {
  height: 30px;
  width: 30px;
}

.auth-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 43px;
  position: absolute;
  right: 17px;
  cursor: pointer;
}
@media screen and (min-width: 1024px) and (max-width: 1473px) {
  .auth-box {
    right: 45px;
  }
}

.auth-label {
  font-size: 12px;
  color: #333333;
}

.view-all-orders {
  padding-top: 1.5px;
  font-weight: bold;
  color: #00aaf3;
}

.view-orders-icon {
  margin: 0 8px 0 15px;
}

.view-dashboard {
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.dashboard-box {
  background-image: url(../images/telstra-gradient-small.svg);
  background-size: cover;
  height: 0;
  position: relative;
  padding-top: 23%;
}
@media screen and (min-width: 640px) {
  .dashboard-box {
    background-image: url(../images/telstra-gradient-large.svg);
    padding-top: 8%;
  }
}

@media screen and (min-width: 1024px) {
  .progress-step-align {
    width: 3.33%;
  }

  .order-progress-box {
    margin: 28px 28px 28px 0px;
  }
}
.plan-name-link {
  cursor: pointer;
}

.order-plan-name-box {
  padding: 17px 45px 14px 0;
  border-bottom: 1px solid #949494;
}

.status-day {
  font-weight: normal;
  font-size: 42px;
  text-align: left;
  color: #555555;
  line-height: 1.1;
}

.status-month-year {
  line-height: 0.6;
}

.dashboard-order-number-box {
  padding-top: 18px;
}

.dashboard-order-number {
  font-size: 12px;
}

.next-step-box {
  padding-bottom: 13px;
}

.dashboard .order-status {
  padding-top: 2px;
}

.dashboard .orders-summary-content {
  border-bottom: none;
}

.dashboard .order-status-label {
  padding-top: 0;
}

.dashboard .order-plan-name-box {
  padding-right: 0;
}

.dashboard .plan-name-content {
  justify-content: space-between;
  align-items: center;
}

.plan-name-chevron-box {
  padding-right: 15px;
  width: 40px;
}

.plan-name-chevron-icon {
  height: 18px;
  width: 15px;
}

.dashboard .plan-name {
  padding-right: 12px;
}

.order-complete {
  padding-top: 21px;
}

.order-complete-state {
  position: absolute;
  right: -13.1px;
  top: 20px;
}

.order-complete-state img {
  vertical-align: baseline;
}

.order-complete-bar {
  height: 41.2px;
}

.guarantee-header {
  padding-top: 30px;
  color: #333333;
  font-size: 16px;
  line-height: 18px;
}

.guarantee-detail {
  padding-top: 10px;
  height: 21px;
  color: #333333;
  font-family: "Helvetica Neue";
  font-size: 14px;
  line-height: 31px;
  display: inline-block;
}
.guarantee-detail .nbn-guarantee-link-td {
  text-decoration: underline;
}
.guarantee-detail .nbn-guarantee-link-td:hover {
  color: #001e82;
  text-decoration: underline;
  text-decoration-color: #001e82;
}

.preorder-complete-bar {
  height: 42px;
}

.preorder-complete {
  padding-top: 21px;
}

.order-processed {
  padding-bottom: 21px;
  border-bottom: 1px solid #9b9b9b;
}

.order-processed-state {
  position: absolute;
  right: -13.1px;
  top: -1px;
}

.preorder-processed {
  padding-bottom: 21px;
  border-bottom: 1px solid #9b9b9b;
}

.service-message-box {
  margin: 15px 15px 0 15px;
  padding: 0 16px;
}
.service-message-box .service-message-label {
  font-size: 18px;
  color: #333333;
  padding: 20px 0 8px 0;
}
.service-message-box img {
  padding-right: 9px;
}
.service-message-box .service-message-content {
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  padding-bottom: 15px;
  line-height: 17px;
  padding-left: 34px;
}
.service-message-box .empty-header {
  padding: 21px;
}
.service-message-box .empty-header img {
  float: left;
}
.service-message-box .empty-header .service-message-content {
  padding-top: 7px;
}

.fail-border {
  border: solid 2px #ee4126;
}

.info-border {
  border: solid 2px #f6a623;
}

.info-bgcolor {
  background-color: #fff9d6;
}

.fail-bgcolor {
  background-color: rgba(255, 0, 0, 0.1176470588);
}

.success-border {
  border: solid 2px #66cc00;
}

.success-bgcolor {
  background-color: rgba(102, 255, 153, 0.168627451);
}

.info-icon {
  padding-top: 4px;
}

.plan-name-box {
  padding: 15px 45px 13px 0;
  border-top: 1px solid #949494;
  border-bottom: 1px solid #949494;
}

.plan-name-content {
  display: flex;
}

.plan-name {
  font-size: 16px;
  text-align: left;
  color: #333333;
  align-self: center;
  line-height: 17px;
}

.plan-icon {
  margin: 0 8px 0 15px;
}

.order-status-label {
  color: #333333;
  line-height: 1.1;
  padding-top: 3px;
}

.orders-summary-content {
  padding: 15px 0 10px 17px;
  border-bottom: 1px solid #949494;
}

.semi-supported-padding {
  padding-bottom: 30px;
}

.order-status {
  font-size: 18px;
  color: #333333;
  line-height: 1.1;
}

.order-summary-day {
  font-weight: normal;
  font-size: 42px;
  text-align: left;
  color: #555555;
  line-height: 1.1;
}

.order-summary-month-year {
  line-height: 0.6;
}

.inclusions, .contact-details {
  padding: 10px 0 10px 17px;
  border-bottom: 1px solid #949494;
}

.contact-info, .connection-address {
  word-wrap: break-word;
  padding-right: 34px;
}

.order-features {
  margin-bottom: 0;
  padding-left: 12px;
}

.order-summary-header {
  padding: 18px 0 5px 17px;
}

.foxtel-box {
  border: solid 1px #1a5199;
  margin: 19px 14px 0 15px;
}

.foxtel-text {
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  padding: 19px 0 10px 15px;
}

.foxtel-more-link {
  padding: 4px 0 13px 14px;
  line-height: 1.1;
}

.foxtel-more-icon {
  padding-right: 4.8px;
}

.order-summary-right, .order-number-box {
  padding-left: 17px;
}

.order-number-box {
  height: 60px;
  justify-content: flex-end;
}

.order-summary-content {
  padding: 15px 0 10px 17px;
}

.feedback {
  margin: auto;
  max-width: 20em;
  font-size: 16px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .feedback {
    width: 350px;
    alignment: center;
  }
}
@media only screen and (max-width: 40em) {
  .feedback {
    padding-left: 2em;
  }
}

.feedbackmy {
  padding: 0em 0em 2em 2em;
  margin: auto;
  max-width: 20em;
  font-size: 16px;
}
@media only screen and (max-width: 40em) {
  .feedbackmy {
    padding-left: 2em;
    margin: auto;
    max-width: 20em;
  }
}

.feedbackmy > iframe:nth-of-type(2) {
  display: none;
}

.feedbackmy > iframe:nth-of-type(3) {
  display: none;
}

.feedbackmy > iframe:nth-of-type(4) {
  display: none;
}

.feedbackmy > iframe:nth-of-type(5) {
  display: none;
}

.feedbackmy > iframe:nth-of-type(6) {
  display: none;
}

.milestone-process {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #9b9b9b;
}

.milestone-process-icon {
  position: absolute;
  right: -13.1px;
  top: 15px;
}

.banner-content-td {
  border-bottom: 2px solid #d0021b;
  margin-bottom: 80px;
}
@media only screen and (max-width: 1023px) {
  .banner-content-td {
    max-width: 75%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 375px) {
  .banner-content-td {
    max-width: 306px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .banner-content-td {
    max-width: 65%;
  }
}

.banner-content-td-blue, .banner-content-td-red {
  margin-bottom: 80px;
}
@media only screen and (max-width: 1023px) {
  .banner-content-td-blue, .banner-content-td-red {
    max-width: 75%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 375px) {
  .banner-content-td-blue, .banner-content-td-red {
    max-width: 306px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .banner-content-td-blue, .banner-content-td-red {
    max-width: 65%;
  }
}
.banner-content-td-blue button, .banner-content-td-red button {
  width: calc(100%);
  text-align: right;
  padding: 0.5rem 1rem 0.5rem 0;
  border-top: 1px solid #e8e8e8;
  margin: 0rem 0rem 0;
  margin-left: 0.25rem;
  max-width: none;
}
.banner-content-td-blue .mt-card, .banner-content-td-red .mt-card {
  padding-bottom: 0;
}
.banner-content-td-blue button:hover, .banner-content-td-red button:hover {
  background: rgba(94, 80, 191, 0.06);
}

.banner-content-td-red {
  border-bottom: 2px solid #d0021b;
}
.banner-content-td-red button:hover {
  background: #fcf0f1;
}

.banner-content-td-blue .able-mt-button span {
  font-size: 1rem;
  color: #0064d2;
}

.banner-content-td-blue .able-mt-button svg {
  stroke: #0064d2;
}

.banner-content-td-blue button, .banner-content-td-red button {
  margin-left: 0;
  justify-content: flex-end;
}

.banner-content-td-blue {
  border-bottom: 2px solid;
  border-image: linear-gradient(90deg, #0064D2 0%, #5CD6E0 31.59%, #5E50BF 100%);
  border-image-slice: 9;
}

.banner-content-td-red .able-mt-button span {
  font-size: 1rem;
  color: #d0021b;
}

.banner-content-td-red .able-mt-button svg {
  stroke: #d0021b;
}

.banner-icon-td, .banner-icon-td-error, .banner-icon-td-info {
  margin-top: 2px;
}
.banner-icon-td svg, .banner-icon-td-error svg, .banner-icon-td-info svg {
  height: 24px;
  width: 24px;
}

.banner-icon-td-info g {
  stroke: #0064d2;
}

.banner-icon-td-error {
  height: 24px;
  width: 24px;
}

.landing-page-error-message-box-td {
  margin: 10px 0px 80px 0px;
  padding: 0 16px;
  box-shadow: 0px 1px 4px 1px #D8D8D8;
  border-bottom: 2px solid #d0021b;
}

.banner-message-content-header-td {
  font-family: "Telstra Akkurat" !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
  font-size: 1.5rem !important;
}
@media only screen and (max-width: 1023px) {
  .banner-message-content-header-td {
    font-size: 16px !important;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 375px) {
  .banner-message-content-header-td {
    font-size: 16px !important;
    margin: 0 auto;
  }
}

.banner-body-td {
  padding: 16px !important;
  display: flex;
}

.banner-subtext-td {
  padding-top: 8px;
  padding-right: 32px;
  font-family: "Telstra Akkurat";
  letter-spacing: 0.15px;
  line-height: 22px;
  white-space: pre-line;
  position: relative;
  top: -0.3em;
  color: #414141;
  margin-bottom: 0;
}
@media only screen and (max-width: 1023px) {
  .banner-subtext-td {
    font-size: 14px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 375px) {
  .banner-subtext-td {
    font-size: 14px;
    margin: 0 auto;
  }
}

.banner-cta-btn {
  margin-top: 0 !important;
}

.order-summary-header {
  padding: 0;
  color: #282828 !important;
  font-family: "Telstra Akkurat";
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.25px;
  margin-bottom: 36px;
}
@media only screen and (max-width: 1023px) {
  .order-summary-header {
    margin: 0 auto 16px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .connection-address-row {
    margin-top: 10px !important;
  }
}

.connection-address-label {
  margin-bottom: 4px;
  font-size: 16px;
}

.order-summary-date-label {
  color: #414141;
  font-family: "Telstra Akkurat";
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-top: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.order-summary-date {
  font-size: 32px;
  font-weight: normal;
}
@media only screen and (max-width: 1023px) {
  .order-summary-date {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

.order-summary-content {
  padding: 0;
  border-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .order-summary-content {
    max-width: 744px;
  }
}
@media only screen and (max-width: 1023px) {
  .order-summary-content {
    margin: 0 auto;
    font-size: 24px;
  }
}

.order-summary-banner {
  margin-top: 40px;
  margin-bottom: 42px;
}
@media only screen and (max-width: 1023px) {
  .order-summary-banner {
    margin-top: 0px;
  }
}

.error-summary-banner {
  margin-bottom: 40px;
}

.order-summary {
  float: left !important;
}

.order-summary-right, .order-number-box {
  flex-direction: row !important;
  justify-content: space-between;
  font-weight: normal;
  padding: 0;
}
.order-summary-right .field-label, .order-number-box .field-label, .order-summary-right .progress-label, .order-number-box .progress-label, .order-summary-right .plan-label, .order-number-box .plan-label, .order-summary-right #old-plan-label, .order-number-box #old-plan-label, .order-summary-right #new-plan-label, .order-number-box #new-plan-label, .order-summary-right .field-value, .order-number-box .field-value, .order-summary-right .progress-details, .order-number-box .progress-details {
  font-size: 20px;
}
@media only screen and (max-width: 1023px) {
  .order-summary-right .field-label, .order-number-box .field-label, .order-summary-right .progress-label, .order-number-box .progress-label, .order-summary-right .plan-label, .order-number-box .plan-label, .order-summary-right #old-plan-label, .order-number-box #old-plan-label, .order-summary-right #new-plan-label, .order-number-box #new-plan-label, .order-summary-right .field-value, .order-number-box .field-value, .order-summary-right .progress-details, .order-number-box .progress-details {
    font-size: 16px;
  }
}

.order-status-box, .connection-address {
  margin-top: 10px;
}

.order-details-header-img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.order-details-header-img-container .order-details-header-img, .order-details-header-img-container .header-img-TD {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .order-details-header-img-container .header-img-TD {
    display: none;
  }
  .order-details-header-img-container .order-details-header-img-desktop {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .order-details-header-img-container .header-img-TD {
    display: none;
  }
  .order-details-header-img-container .order-details-header-img-tab-port {
    display: block;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-header-img-container .header-img-TD {
    display: none;
  }
  .order-details-header-img-container .order-details-header-img-tab-land {
    display: block;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-header-img-container {
    font-size: 16px;
  }
  .order-details-header-img-container .header-img-TD {
    display: none;
  }
  .order-details-header-img-container .order-details-header-img-mobile {
    display: block;
  }
}

.chat-btn-TD {
  position: fixed;
  bottom: 0;
  right: 40px;
  width: 165px;
  height: 48px;
  background: #1A5199;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Telstra Akkurat";
  font-size: 16px;
  letter-spacing: 0.15px;
  user-select: none;
  z-index: 2000;
}

.chat-btn-icon-TD {
  margin-right: 8px;
}

.iframe-silent-signin {
  width: 0;
  height: 0;
  border: none;
}

.module-navigation-breadcrumb nav ul {
  margin-left: 0px !important;
}

#Order-Number, #Order-Status-Text, #Connection-address-text {
  text-align: end !important;
}

.timeline-container {
  margin: 3rem 0 2rem;
}
@media only screen and (max-width: 1023px) {
  .timeline-container {
    padding: 2rem 0;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .timeline-container {
    max-width: 744px;
  }
}

.order-timeline .timeline-progress-line {
  top: 3px !important;
}
.order-timeline * {
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .order-timeline * {
    font-size: 16px !important;
  }
}

.order-timeline-event .milestone-date span {
  line-height: 40px;
}
.order-timeline-event .milestone-date .delivery-date-title {
  font-weight: 700;
}

.order-timeline-event .nbn-health-check-wrapper {
  display: flex;
  margin-top: 16px;
}
.order-timeline-event .nbn-health-check-info-icon {
  margin-right: 8px;
}
.order-timeline-event .nbn-health-check-info-icon img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.timeline-content-td {
  font-size: 20px;
  color: #414141;
  font-weight: normal;
  padding-bottom: 1.8rem;
}

.milestone-title {
  font-size: 20px;
  color: #282828;
  font-weight: 700;
}

.order-timeline-icon {
  border: transparent;
  background: unset;
}

.timeline-progress-line {
  width: 4px;
}

.timeline-content-td .attendance-label, .timeline-content-td .reschedule-message-content, .timeline-content-td .progress-details {
  color: #414141;
  padding: unset;
}
.timeline-content-td .reschedule-message .progress-label {
  margin-bottom: 1rem;
}
.timeline-content-td .reschedule-message .reschedule-message-content .configurable-contact-message {
  margin-top: 1rem;
}

.logistic-label-td {
  font-size: 20px;
  color: #282828;
  line-height: 28px;
  font-weight: 700;
}

.logistic-link-td {
  font-weight: 400;
  text-decoration: underline;
  color: #0062DA;
}
.logistic-link-td .tracking-url {
  font-size: 16px;
}

.logistic-link-td :hover {
  color: #001e82;
  text-decoration: underline;
  text-decoration-color: #001e82;
}

.logistic-img-td {
  width: 64px;
  height: 64px;
}

.logistic-panel-td {
  display: inline-flex;
  margin: 1rem 0;
  border: 1px solid #D8D8D8;
  padding: 1rem 5rem 1rem 1rem;
}
@media only screen and (max-width: 767px) {
  .logistic-panel-td {
    padding: 1rem;
    height: 82px;
  }
  .logistic-panel-td .logistic-img-box {
    margin-right: unset;
    min-width: 33.33%;
  }
  .logistic-panel-td .logistic-img-box img {
    height: 100%;
  }
  .logistic-panel-td .logistic-link-td .tracking-url {
    white-space: nowrap;
    font-size: 14px !important;
  }
}

.order-timeline-event:first-child > div:nth-child(3) > div:nth-child(3) {
  padding: 0rem 1rem 0.5rem;
  margin-top: unset;
}

.add-to-calender-td .react-add-to-calendar .react-add-to-calendar__button:before {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35' width='20px' height='20px' fill='rgb(0, 100, 210)'><path d='M24 10.6H13.4V0h-2.8v10.6H0v2.8h10.6V24h2.8V13.4H24z' /></svg>");
  width: 20px;
  height: 20px;
  top: calc(50% - 22px);
  left: 0px;
  margin-top: 6px;
}

.add-to-calender-td .react-add-to-calendar__button {
  white-space: nowrap;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: -0.5rem;
  color: #0064d2;
}

.add-to-calender-td .react-add-to-calendar__button:hover, .add-to-calender-td .react-add-to-calendar__button:hover:before {
  color: #001e82;
}

.attendance-notification {
  font-weight: 700;
  font-size: 20px;
  color: #282828;
}

.reschedule-status-td {
  font-weight: 700;
  font-size: 20px;
  color: #282828;
  margin-bottom: 1rem;
}

.move-reschedule-td {
  line-height: 24px;
}

.modem-plugin-txt {
  margin-top: 0.35rem;
}

.modem-plugin-icon-td {
  width: 22px;
  height: 22px;
  margin-top: 0.35rem !important;
  margin-right: 0.8rem;
}

.modem-plugin-self-install span {
  line-height: 28px;
}

.contact-us-btn-td {
  margin: 1rem 0;
}

.react-add-to-calendar__dropdown {
  width: 183px;
  z-index: 1;
}

.progress-details.hardware-items ul {
  list-style: none;
}
.progress-details.hardware-items ul li {
  position: relative;
}
.progress-details.hardware-items ul li:before {
  content: "";
  position: absolute;
  left: -20px;
  top: 11px;
  width: 5.5px;
  height: 5.5px;
  background-color: #414141;
  border-radius: 50%;
}

.docked-btn-container {
  max-width: 1200px;
  margin: 0 auto -55px;
}
@media only screen and (max-width: 1300px) {
  .docked-btn-container .footer-dock-button-container {
    margin-right: 1.5rem;
  }
}

.timeline-content-td .appointment-box-td {
  margin-top: 0.7rem;
}
.timeline-content-td .appointment-box-td .progress-label {
  font-size: 20px;
  font-weight: 700;
  color: #282828;
}
.timeline-content-td .appointment-box-td span {
  line-height: 28px;
}
.timeline-content-td #Hardware-Delivery-Label {
  color: #282828;
  font-weight: 700;
}
.timeline-content-td #NBN-Appointment-Static-Heading, .timeline-content-td #Telstra-Appointment-Static-Heading, .timeline-content-td #Uniti-Appointment-Static-Heading {
  color: #282828;
  font-weight: 700;
}
.timeline-content-td #NBN-Appointment-Type-Container, .timeline-content-td #Telstra-Appointment-Type-Container, .timeline-content-td #Uniti-Appointment-Type-Container {
  display: flex;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.timeline-content-td .hardware-items span p {
  margin-bottom: unset;
}

.timeline-content-td .progress-label {
  font-weight: 400;
}

.hardware-delivery-startrack {
  margin-top: -0.5rem;
}

.small-icon-td, .small-icon-td-error {
  width: 22px;
  height: 22px;
  margin-top: -0.35rem;
  margin-right: 0.8rem;
}

.small-icon-td {
  margin-top: 0.2rem;
}

.appointment-info-td {
  padding-bottom: 15px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.appointment-info-td p {
  margin: 0;
}
.appointment-info-td-btn {
  margin-top: 0.5rem;
  padding: unset;
  padding-left: 0.2em;
}

.message-flex {
  display: flex;
}

.appointment-reschedule-btn {
  padding: unset;
  padding-left: 0.3rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.progress-link-td {
  color: #0062DA;
  font-weight: 400;
  line-height: 28px;
}

.telstra-pro-inst-apt {
  margin: 1.75rem 0 0.5rem;
}

.react-add-to-calendar__dropdown ul li a i:before {
  font-family: "Fontawesome";
  font-size: 1.15rem;
}

.iframe-container {
  position: fixed;
  top: 20%;
  left: 15%;
  width: 70%;
  height: 70%;
}

.iframe-popup-td {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 1012;
}

.close-btn-td {
  position: absolute;
  z-index: 1012;
  width: 1.6%;
  top: 2%;
  right: 2%;
  cursor: pointer;
}

.popup-td {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 10000px;
  height: 10000px;
  background-color: #949494;
  opacity: 0.898039;
  z-index: 1011;
}

.dashboard {
  background-color: white;
}

.order-dashboard-td {
  max-width: 744px;
}
.order-dashboard-title-td {
  color: #282828;
  font-family: "Telstra Akkurat";
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 52px;
  margin-top: 32px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 767px) {
  .order-dashboard-title-td {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: 0px;
  }
}

.dashboard-inactive-tab-td, .dashboard-active-tab-td {
  display: flex;
  justify-content: center;
  padding: 24px 0;
  width: 49.5%;
  font-family: "Telstra Akkurat";
  font-size: 18px;
  letter-spacing: -0.15px;
  line-height: 23px;
}

.dashboard-tabs-td {
  display: flex;
}

.dashboard-active-tab-td {
  color: #0064D2;
  border-bottom: 2px solid #0064D2;
}

.dashboard-inactive-tab-td {
  cursor: pointer;
  color: #414141;
  border-bottom: 2px solid #E6E6E6;
}
.dashboard-inactive-tab-td:hover {
  background-color: #ECF7FE;
  color: #001D81;
  border-bottom: 2px solid #001D81;
}

.orders-box-td {
  margin-top: 44px;
  margin-bottom: 76px;
}

.plan-name-td {
  color: #282828;
  font-family: "Telstra Akkurat";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 767px) {
  .plan-name-td {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.plan-status-td {
  border-radius: 12px;
  background-color: #D8EFFE;
  width: fit-content;
  padding: 2px 12px;
  color: #001E82;
  font-family: "Telstra Akkurat";
  font-size: 14px;
  letter-spacing: -0.15px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 24px;
}
.plan-status-td.completed {
  color: white;
  background-color: #008A00;
}
.plan-status-td.cancelled {
  color: white;
  background-color: #D0021B;
}

.plan-detail-item-td {
  margin-bottom: 24px;
  color: #414141;
  font-family: "Telstra Akkurat";
  display: flex;
  width: 100%;
}
.plan-detail-item-td div {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 28px;
  flex: 1;
}
@media only screen and (max-width: 767px) {
  .plan-detail-item-td div {
    font-size: 16px;
    line-height: 24px;
  }
}
.plan-detail-item-td div:last-child {
  text-align: right;
}

.plan-show-details-link-td {
  text-decoration: none !important;
  display: flex;
  align-items: center;
}
.plan-show-details-link-td span:first-child {
  color: #0062DA;
  font-family: "Telstra Akkurat";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  margin-right: 4px;
}
@media only screen and (max-width: 767px) {
  .plan-show-details-link-td span:first-child {
    font-size: 16px;
    line-height: 24px;
  }
}
.plan-show-details-link-td .right-arrow {
  vertical-align: middle;
  display: inline-block;
  width: 15px;
  height: 12px;
  background-color: #0062da;
  mask: url("../images/RightArrowTD.svg") no-repeat center;
  margin-left: 0.2rem;
  transition: margin-left 0.2s ease;
}
.plan-show-details-link-td:hover {
  color: #001e82;
}
.plan-show-details-link-td:hover > .right-arrow {
  margin-left: 0.5rem;
}

.order-separator-td {
  border-bottom: 1px solid #E8E8E8;
  margin: 36px 0;
}

.session-timeout-popup {
  width: 40vw;
  padding: 50px 30px 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 2px solid;
  border-top: 5px solid;
  border-image: linear-gradient(90deg, #0064D2 0%, #5CD6E0 31.59%, #5E50BF 100%);
  border-image-slice: 9;
}
@media only screen and (max-width: 1023px) {
  .session-timeout-popup {
    width: 60vw;
  }
}
@media only screen and (max-width: 375px) {
  .session-timeout-popup {
    padding: 40px 20px 30px;
    width: 90vw;
  }
}
.session-timeout-popup > div {
  align-self: flex-end;
  margin-top: 30px;
}
.session-timeout-popup h3 {
  font-size: 1.5625rem;
}
@media only screen and (max-width: 1023px) {
  .session-timeout-popup h3 {
    font-size: 1rem;
  }
}
.session-timeout-popup h2 {
  font-weight: bold;
  font-size: 2.2rem;
}
.session-timeout-popup h2 span {
  font-size: inherit;
  font-weight: inherit;
  white-space: nowrap;
}
@media only screen and (max-width: 1023px) {
  .session-timeout-popup h2 {
    font-size: 1.5rem;
  }
}

.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(148, 148, 148, 0.898039);
  z-index: 3011;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-details-timeline .timeline-group-header {
  margin-bottom: 2.3em;
}
.order-details-timeline .timeline-group-header span {
  color: #414141;
  font-size: 1.4em;
  letter-spacing: 0.15px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-group-header span {
    font-size: 1.1em;
  }
}
.order-details-timeline h3 {
  font-size: 1.3rem;
}
.order-details-timeline .timeline-group-footer {
  margin-bottom: 8.6em;
}
.order-details-timeline .timeline-group-footer h3 {
  font-weight: bold;
  color: #282828;
}
.order-details-timeline .timeline-group-footer span {
  font-size: 16px;
  color: #414141;
}
.order-details-timeline .timeline-container {
  margin-top: 0;
  padding: 0;
}
.order-details-timeline .all-timeline-icon {
  margin-top: -1px;
  margin-right: -2px;
}
.order-details-timeline .order-timeline {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-left: 14px !important;
  width: 100% !important;
}
.order-details-timeline .order-timeline .order-timeline-event:nth-last-child(2) .timeline-content-td {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .order-timeline {
    margin-left: 0 !important;
  }
}
.order-details-timeline .timeline-content-td {
  width: 100% !important;
  border-bottom-color: rgba(0, 0, 0, 0.16) !important;
  padding-bottom: 24px;
}
.order-details-timeline .timeline-content-td p,
.order-details-timeline .timeline-content-td button {
  font-size: 16px;
}
.order-details-timeline .timeline-content-td p {
  margin-bottom: 0.4em;
  line-height: 22px;
}
.order-details-timeline .timeline-content-td p:last-child {
  margin-bottom: 0 !important;
}
.order-details-timeline .timeline-content-td button {
  padding-top: 0;
  padding-bottom: 0;
}
.order-details-timeline .timeline-group-footer {
  margin-bottom: 100px !important;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-group-footer {
    margin-top: 24px;
  }
  .order-details-timeline .timeline-group-footer h3 {
    font-size: 16px !important;
  }
}
.order-details-timeline .timeline-section {
  display: flex;
}
.order-details-timeline .timeline-section .red-alert-icon--edgar {
  flex: 0 0 auto;
  height: 20px;
  width: 21px;
  margin-right: 10px;
  margin-top: -6px;
}
.order-details-timeline .timeline-section .red-alert-icon--edgar img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .red-alert-icon--edgar {
    margin-top: -5px;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-timeline .timeline-section .red-alert-icon--edgar {
    margin-top: -3px;
  }
}
.order-details-timeline .timeline-section .info-icon--edgar {
  flex: 0 0 auto;
  height: 19.5px;
  width: 19.5px;
  margin-right: 10px;
  margin-top: -6px;
}
.order-details-timeline .timeline-section .info-icon--edgar img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .info-icon--edgar {
    margin-top: -5px;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-timeline .timeline-section .info-icon--edgar {
    margin-top: -3px;
  }
}
.order-details-timeline .timeline-second-message {
  margin-top: 9px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-second-message {
    margin-top: 17px;
  }
}
.order-details-timeline .timeline-link-text {
  margin-top: 11px;
}

.order-details-header {
  color: #282828;
  margin-bottom: 2.3em;
}
.order-details-header h1 {
  font-size: 2.9em;
  font-weight: bold;
  letter-spacing: -0.25px;
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .order-details-header h1 {
    font-size: 2.3em;
  }
}
.order-details-header h3 {
  color: #282828;
  margin-bottom: 0;
  font-size: 1.4em;
  text-transform: uppercase;
}
@media only screen and (max-width: 1023px) {
  .order-details-header h3 {
    font-size: 1em;
  }
}
.order-details-header h2 {
  font-size: 1.9em;
  letter-spacing: -0.25px;
  margin-top: 0.1em;
}
@media only screen and (max-width: 1023px) {
  .order-details-header h2 {
    font-size: 1.4em;
    margin-top: 1em;
  }
}
.order-details-header .energy-header-icon {
  height: 32px;
  margin-right: 8px;
  margin-top: -4px;
}
@media only screen and (max-width: 1023px) {
  .order-details-header .energy-header-icon {
    height: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-header .energy-header-icon {
    height: 26px;
  }
}

.order-details-header__title {
  display: flex;
  align-items: center;
  margin: 32px 0 16px;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__title {
    margin: 0 auto 16px;
  }
}

.order-details-header__summary {
  display: flex;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__summary {
    margin-top: 1.4em;
  }
}

.order-details-header__left {
  color: #414141;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__left {
    width: 100%;
  }
}

.order-details-header__row {
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  max-width: 32.7em;
}
.order-details-header__row span {
  font-size: 20px !important;
  line-height: 28px !important;
}
.order-details-header__row:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__row {
    margin-top: 0;
    max-width: 22.6em;
  }
  .order-details-header__row span {
    line-height: 22px !important;
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-header__row {
    font-size: 16px;
    max-width: unset;
  }
}

.timeline-group-tabs {
  width: 80%;
  color: #282828;
  margin: 0 0 2.3em;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs {
    margin: 0 auto;
  }
}
.timeline-group-tabs span {
  font-size: 1.7em;
  font-weight: bold;
  margin-left: 0.4em;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs span {
    font-size: 1.4em;
  }
}
.timeline-group-tabs img {
  vertical-align: text-bottom;
}
.timeline-group-tabs .energy-tab-icon {
  height: 26px;
  margin-top: -2px;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs .energy-tab-icon {
    height: 24px;
  }
}
@media only screen and (max-width: 375px) {
  .timeline-group-tabs .energy-tab-icon {
    height: 20px;
  }
}
.timeline-group-tabs ul {
  margin-left: 0;
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  line-height: 2em;
  width: fit-content;
  display: flex;
}
.timeline-group-tabs ul li {
  width: 16.4em;
  padding: 19px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs ul li {
    width: 11.3em;
  }
}
@media only screen and (max-width: 375px) {
  .timeline-group-tabs ul li {
    width: 10.3em;
  }
}
.timeline-group-tabs ul li:hover {
  cursor: pointer;
}
.timeline-group-tabs ul .active-group {
  color: #0064d2;
  border-bottom: 2px solid #0064d2;
  background-color: #ecf7fe;
}

.inner-container--edgar .banner-content-td-blue, .inner-container--edgar .banner-content-td-red {
  margin-bottom: 42px !important;
  margin-top: 30px;
  box-shadow: 0px 8px 10px 1px rgba(0, 4, 10, 0.14), 0px 3px 14px 2px rgba(0, 4, 10, 0.04), 0px 5px 5px -3px rgba(0, 4, 10, 0.2) !important;
}
.inner-container--edgar .banner-content-td-blue .mt-card, .inner-container--edgar .banner-content-td-red .mt-card {
  box-shadow: none;
}
.inner-container--edgar .banner-content-td-blue button, .inner-container--edgar .banner-content-td-red button {
  margin-left: 0;
  justify-content: flex-end;
}
.inner-container--edgar .banner-content-td-red {
  border-bottom: 2px solid #d0021b !important;
}
.inner-container--edgar .banner-message-content-header-td {
  font-size: 20px !important;
  line-height: 28px !important;
}
@media only screen and (max-width: 1023px) {
  .inner-container--edgar .banner-message-content-header-td {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
.inner-container--edgar .banner-subtext-td {
  font-size: 16px !important;
  letter-spacing: 0.15px !important;
  line-height: 22px !important;
}
@media only screen and (max-width: 1023px) {
  .inner-container--edgar .banner-subtext-td {
    font-size: 14px !important;
    letter-spacing: 0.18px !important;
    line-height: 20px !important;
  }
}

.order-details-timeline .timeline-group-header {
  margin-bottom: 2.3em;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-group-header {
    margin-bottom: 24px;
  }
}
.order-details-timeline .timeline-group-header span {
  color: #414141;
  font-size: 1.4em;
  letter-spacing: 0.15px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-group-header span {
    font-size: 16px;
  }
}
.order-details-timeline h3 {
  font-size: 1.3rem;
}
.order-details-timeline .timeline-group-footer {
  margin-bottom: 8.6em;
}
.order-details-timeline .timeline-group-footer h3 {
  font-weight: bold;
  color: #282828;
}
.order-details-timeline .timeline-group-footer span {
  font-size: 16px;
  color: #414141;
}
.order-details-timeline .timeline-container {
  margin-top: 0;
  padding: 0;
}
.order-details-timeline .all-timeline-icon {
  margin-top: -1px;
  margin-right: -2px;
}
.order-details-timeline .order-timeline {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-left: 14px !important;
  width: 100% !important;
}
.order-details-timeline .order-timeline .order-timeline-event:nth-last-child(2) .timeline-content-td {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .order-timeline {
    margin-left: 0 !important;
  }
}
.order-details-timeline .timeline-content-td {
  width: 100% !important;
  border-bottom-color: rgba(0, 0, 0, 0.16) !important;
  padding-bottom: 24px;
}
.order-details-timeline .timeline-content-td p,
.order-details-timeline .timeline-content-td button {
  font-size: 16px;
}
.order-details-timeline .timeline-content-td p {
  margin-bottom: 0.4em;
  line-height: 22px;
}
.order-details-timeline .timeline-content-td p:last-child {
  margin-bottom: 0 !important;
}
.order-details-timeline .timeline-content-td button {
  padding-top: 0;
  padding-bottom: 0;
}
.order-details-timeline .timeline-group-footer {
  margin-bottom: 100px !important;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-group-footer {
    margin-top: 24px;
  }
  .order-details-timeline .timeline-group-footer h3 {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .milestone-title {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
.order-details-timeline .timeline-section {
  display: flex;
  flex-direction: row;
  line-height: 40px;
  align-items: baseline;
}
.order-details-timeline .timeline-section button {
  font-size: 20px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section button {
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0;
    margin: 8px 0 0;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .add-to-calender-td {
    padding: 0;
  }
  .order-details-timeline .timeline-section .add-to-calender-td button {
    font-size: 16px !important;
    line-height: 24px !important;
    padding-left: 24px;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .add-to-calender-td .react-add-to-calendar__button::before {
    margin-top: 10px;
    padding: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .add-to-calender-td .react-add-to-calendar__button {
    margin: 8px 0 16px 0;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section * {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section ul {
    margin: 0 0 0 16px;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section li {
    font-size: 16px !important;
    line-height: 22px !important;
    margin: 0 0 4px 0;
  }
  .order-details-timeline .timeline-section li:last-child {
    margin: 0;
  }
}
.order-details-timeline .timeline-section .timeline-message {
  line-height: 22px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .timeline-message {
    font-size: 16px;
  }
}
.order-details-timeline .timeline-section .timeline-message-newline:first-child {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .timeline-message-newline:first-child {
    margin-top: 10px;
  }
}
.order-details-timeline .timeline-section .timeline-message-newline:last-child {
  margin-top: 10px;
}
.order-details-timeline .timeline-section .red-alert-icon--edgar {
  flex: 0 0 auto;
  height: 20px;
  width: 21px;
  margin-right: 10px;
  margin-top: -3px;
}
.order-details-timeline .timeline-section .red-alert-icon--edgar img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section .red-alert-icon--edgar {
    margin-top: -3px;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-timeline .timeline-section .red-alert-icon--edgar {
    margin-top: -3px;
  }
}
.order-details-timeline .timeline-section .info-icon--edgar {
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  margin-top: 0;
  margin-right: 8px;
}
.order-details-timeline .timeline-section .info-icon--edgar img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section:last-child .timeline-message {
    margin-bottom: 0;
  }
}
.order-details-timeline .timeline-section.timeline-bottom-label-text {
  margin-top: 8px;
}
.order-details-timeline .timeline-section.timeline-middle-label-text {
  margin-top: -2px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-section.timeline-middle-label-text {
    margin-top: 8px;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-timeline .timeline-section.timeline-middle-label-text {
    margin-top: 8px;
  }
}
.order-details-timeline .timeline-second-message {
  margin-top: 9px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-second-message {
    margin-top: 17px;
  }
}
.order-details-timeline .timeline-link-text {
  margin-top: 11px;
}
.order-details-timeline .milestone-heading {
  font-size: 20px;
  color: #282828;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .milestone-heading {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
.order-details-timeline .nbn-guarantee-message {
  margin-left: 20px;
  margin-bottom: 0;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .nbn-guarantee-message {
    margin-left: auto;
  }
}
.order-details-timeline .nbn-guarantee-message * {
  font-size: 20px;
  margin-bottom: 0;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .nbn-guarantee-message * {
    padding-top: 30px;
    font-size: 16px;
    line-height: 22px;
  }
}
.order-details-timeline .nbn-guarantee-link {
  text-decoration: underline;
}
.order-details-timeline #connection-date-label {
  width: 704px;
  height: 22px;
  color: #414141;
  font-size: 16px;
  font-family: Telstra Akkurat;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 22px;
}
.order-details-timeline #connection-date {
  width: 704px;
  height: 34px;
  color: #282828;
  font-size: 24px;
  font-family: Telstra Akkurat;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 34px;
  margin-bottom: 56px;
}
@media only screen and (max-width: 375px) {
  .order-details-timeline #connection-date {
    line-height: 28px;
    font-size: 20px;
    height: 28px;
    margin-bottom: 40px;
  }
}
.order-details-timeline .all-timeline-icon {
  margin-top: -2px;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .logistic-panel-td {
    height: 96px;
    width: 260px;
    margin: 24px 0 0;
    padding: 20px 16px;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .logistic-label-td {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .logistic-img-td {
    height: 56px;
    width: 56px;
  }
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .logistic-panel-td .logistic-img-box {
    margin-right: 16px;
    min-width: unset;
  }
}
.order-details-timeline .logistic-link-td {
  line-height: 21px;
  margin-top: auto;
}
@media only screen and (max-width: 1023px) {
  .order-details-timeline .timeline-lozenge {
    display: flex;
    padding-top: 8px;
    margin-bottom: 24px;
  }
  .order-details-timeline .timeline-lozenge span {
    font-size: 14px !important;
    line-height: 20px;
    padding-top: 1px;
  }
}
.order-details-timeline .order-timeline-event {
  padding-left: 40px !important;
}
.order-details-timeline .timeline-progress-line {
  left: 12px !important;
}
.order-details-timeline .order-timeline-icon {
  width: 24px !important;
  height: 24px !important;
  margin-left: 0 !important;
}
.order-details-timeline .order-timeline-icon span {
  width: 24px !important;
  height: 24px !important;
}

.order-details-header {
  color: #282828;
  margin-bottom: 2.3em;
}
@media only screen and (max-width: 1023px) {
  .order-details-header {
    margin-bottom: 24px;
  }
}
.order-details-header h1 {
  font-size: 2.9em;
  font-weight: bold;
  letter-spacing: -0.25px;
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .order-details-header h1 {
    font-size: 2.3em;
  }
}
.order-details-header h3 {
  color: #282828;
  margin-bottom: 0;
  font-size: 1.4em;
  text-transform: uppercase;
}
@media only screen and (max-width: 1023px) {
  .order-details-header h3 {
    font-size: 1em;
  }
}
.order-details-header h2 {
  font-size: 1.9em;
  letter-spacing: -0.25px;
  margin-top: 0.1em;
}
@media only screen and (max-width: 1023px) {
  .order-details-header h2 {
    font-size: 1.4em;
    margin-top: 1em;
  }
}
.order-details-header .energy-header-icon {
  height: 32px;
  margin-right: 8px;
  margin-top: -4px;
}
@media only screen and (max-width: 1023px) {
  .order-details-header .energy-header-icon {
    height: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-header .energy-header-icon {
    height: 26px;
  }
}
.order-details-header .order-details-header__addition-details-button button {
  padding: 0 !important;
}
.order-details-header .iframe-container {
  z-index: 100;
}
@media only screen and (max-width: 1023px) {
  .order-details-header .iframe-container {
    top: 10%;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    border-radius: 10px;
  }
}
.order-details-header .iframe-container .order-details-header__addition-details {
  background-color: #f4f4f4;
}
@media only screen and (max-width: 1023px) {
  .order-details-header .iframe-container .order-details-header__addition-details {
    border-radius: 10px;
  }
}
.order-details-header .iframe-container .order-details-header__addition-details .cta-section {
  padding: 20px;
  background-color: #f9f9f9;
  color: #1964c8;
}
.order-details-header .iframe-container .order-details-header__addition-details .order-details-header__addition-details-heading {
  margin: 30px 20px 20px;
  font-weight: bold;
  font-size: large;
}
.order-details-header .iframe-container .order-details-header__addition-details .order-details-header__addition-details-list {
  background-color: white;
  padding-left: 20px;
  border-top: 2px solid #e7e7e7;
  border-bottom: 2px solid #e7e7e7;
}
.order-details-header .iframe-container .order-details-header__addition-details .order-details-header__addition-details-key {
  font-weight: bold;
}
.order-details-header .iframe-container .order-details-header__addition-details .order-details-header__addition-details-listitem {
  padding: 15px 0;
  border-bottom: 1px solid #e7e7e7;
}
.order-details-header .iframe-container .order-details-header__addition-details .order-details-header__addition-details-listitem :last-child {
  border-bottom: none;
}

.order-details-header__title {
  display: flex;
  align-items: center;
  margin: 32px 0 16px;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__title {
    margin: 0 auto 16px;
  }
}

.order-details-header__summary {
  display: flex;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__summary {
    margin-top: 1.4em;
  }
}

.order-details-header__left {
  color: #414141;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__left {
    width: 100%;
  }
}

.order-details-header__row {
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  max-width: 32.7em;
}
.order-details-header__row span {
  font-size: 20px !important;
  line-height: 28px !important;
}
.order-details-header__row:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 1023px) {
  .order-details-header__row {
    margin-top: 0;
    max-width: 22.6em;
  }
  .order-details-header__row span {
    line-height: 22px !important;
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 375px) {
  .order-details-header__row {
    font-size: 16px;
    max-width: unset;
  }
}

.order-details-header__date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.order-details-header__date span {
  text-align: right;
}
.order-details-header__date a {
  margin-right: 2px;
}

.timeline-group-tabs {
  width: 80%;
  color: #282828;
  margin: 0 0 2.3em;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs {
    margin: 0 auto;
  }
}
.timeline-group-tabs span {
  font-size: 1.7em;
  font-weight: bold;
  margin-left: 0.4em;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs span {
    font-size: 1.4em;
  }
}
.timeline-group-tabs img {
  vertical-align: text-bottom;
}
.timeline-group-tabs .energy-tab-icon {
  height: 26px;
  margin-top: -2px;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs .energy-tab-icon {
    height: 24px;
  }
}
@media only screen and (max-width: 375px) {
  .timeline-group-tabs .energy-tab-icon {
    height: 20px;
  }
}
.timeline-group-tabs ul {
  margin-left: 0;
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  line-height: 2em;
  width: fit-content;
  display: flex;
}
.timeline-group-tabs ul li {
  width: 16.4em;
  padding: 19px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1023px) {
  .timeline-group-tabs ul li {
    width: 11.3em;
  }
}
@media only screen and (max-width: 375px) {
  .timeline-group-tabs ul li {
    width: 10.3em;
  }
}
.timeline-group-tabs ul li:hover {
  cursor: pointer;
}
.timeline-group-tabs ul .active-group {
  color: #0064d2;
  border-bottom: 2px solid #0064d2;
  background-color: #ecf7fe;
}

.inner-container--edgar .banner-content-td-blue, .inner-container--edgar .banner-content-td-red {
  margin-bottom: 42px !important;
  margin-top: 30px;
  box-shadow: 0px 8px 10px 1px rgba(0, 4, 10, 0.14), 0px 3px 14px 2px rgba(0, 4, 10, 0.04), 0px 5px 5px -3px rgba(0, 4, 10, 0.2) !important;
}
.inner-container--edgar .banner-content-td-blue .mt-card, .inner-container--edgar .banner-content-td-red .mt-card {
  box-shadow: none;
}
.inner-container--edgar .banner-content-td-blue button, .inner-container--edgar .banner-content-td-red button {
  margin-left: 0;
  justify-content: flex-end;
}
.inner-container--edgar .banner-content-td-red {
  border-bottom: 2px solid #d0021b !important;
}
.inner-container--edgar .banner-message-content-header-td {
  font-size: 20px !important;
  line-height: 28px !important;
}
@media only screen and (max-width: 1023px) {
  .inner-container--edgar .banner-message-content-header-td {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
.inner-container--edgar .banner-subtext-td {
  font-size: 16px !important;
  letter-spacing: 0.15px !important;
  line-height: 22px !important;
}
@media only screen and (max-width: 1023px) {
  .inner-container--edgar .banner-subtext-td {
    font-size: 14px !important;
    letter-spacing: 0.18px !important;
    line-height: 20px !important;
  }
}